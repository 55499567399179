exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-us-tsx": () => import("./../../../src/pages/about-us.tsx" /* webpackChunkName: "component---src-pages-about-us-tsx" */),
  "component---src-pages-authors-tsx": () => import("./../../../src/pages/authors.tsx" /* webpackChunkName: "component---src-pages-authors-tsx" */),
  "component---src-pages-cards-index-tsx": () => import("./../../../src/pages/cards/index.tsx" /* webpackChunkName: "component---src-pages-cards-index-tsx" */),
  "component---src-pages-casual-8-ball-pool-index-tsx": () => import("./../../../src/pages/casual/8-ball-pool/index.tsx" /* webpackChunkName: "component---src-pages-casual-8-ball-pool-index-tsx" */),
  "component---src-pages-casual-8-ball-pool-learn-tsx": () => import("./../../../src/pages/casual/8-ball-pool/learn.tsx" /* webpackChunkName: "component---src-pages-casual-8-ball-pool-learn-tsx" */),
  "component---src-pages-casual-abcrummy-tsx": () => import("./../../../src/pages/casual/abcrummy.tsx" /* webpackChunkName: "component---src-pages-casual-abcrummy-tsx" */),
  "component---src-pages-casual-carrom-index-tsx": () => import("./../../../src/pages/casual/carrom/index.tsx" /* webpackChunkName: "component---src-pages-casual-carrom-index-tsx" */),
  "component---src-pages-casual-carrom-learn-tsx": () => import("./../../../src/pages/casual/carrom/learn.tsx" /* webpackChunkName: "component---src-pages-casual-carrom-learn-tsx" */),
  "component---src-pages-casual-chess-learn-tsx": () => import("./../../../src/pages/casual/chess/learn.tsx" /* webpackChunkName: "component---src-pages-casual-chess-learn-tsx" */),
  "component---src-pages-casual-chinese-checkers-hop-pop-tsx": () => import("./../../../src/pages/casual/chinese-checkers-hop-pop.tsx" /* webpackChunkName: "component---src-pages-casual-chinese-checkers-hop-pop-tsx" */),
  "component---src-pages-casual-dots-dash-tsx": () => import("./../../../src/pages/casual/dots-dash.tsx" /* webpackChunkName: "component---src-pages-casual-dots-dash-tsx" */),
  "component---src-pages-casual-index-tsx": () => import("./../../../src/pages/casual/index.tsx" /* webpackChunkName: "component---src-pages-casual-index-tsx" */),
  "component---src-pages-casual-learn-cricket-tsx": () => import("./../../../src/pages/casual/learn-cricket.tsx" /* webpackChunkName: "component---src-pages-casual-learn-cricket-tsx" */),
  "component---src-pages-casual-match-3-fruitkaat-tsx": () => import("./../../../src/pages/casual/match-3-fruitkaat.tsx" /* webpackChunkName: "component---src-pages-casual-match-3-fruitkaat-tsx" */),
  "component---src-pages-casual-warship-tsx": () => import("./../../../src/pages/casual/warship.tsx" /* webpackChunkName: "component---src-pages-casual-warship-tsx" */),
  "component---src-pages-certifications-tsx": () => import("./../../../src/pages/certifications.tsx" /* webpackChunkName: "component---src-pages-certifications-tsx" */),
  "component---src-pages-contact-us-tsx": () => import("./../../../src/pages/contact-us.tsx" /* webpackChunkName: "component---src-pages-contact-us-tsx" */),
  "component---src-pages-deposit-offers-tsx": () => import("./../../../src/pages/deposit-offers.tsx" /* webpackChunkName: "component---src-pages-deposit-offers-tsx" */),
  "component---src-pages-diwali-campaign-tsx": () => import("./../../../src/pages/diwali-campaign.tsx" /* webpackChunkName: "component---src-pages-diwali-campaign-tsx" */),
  "component---src-pages-earn-money-tsx": () => import("./../../../src/pages/earn-money.tsx" /* webpackChunkName: "component---src-pages-earn-money-tsx" */),
  "component---src-pages-fairplay-policy-tsx": () => import("./../../../src/pages/fairplay-policy.tsx" /* webpackChunkName: "component---src-pages-fairplay-policy-tsx" */),
  "component---src-pages-faqs-cards-tsx": () => import("./../../../src/pages/faqs/cards.tsx" /* webpackChunkName: "component---src-pages-faqs-cards-tsx" */),
  "component---src-pages-faqs-casual-tsx": () => import("./../../../src/pages/faqs/casual.tsx" /* webpackChunkName: "component---src-pages-faqs-casual-tsx" */),
  "component---src-pages-faqs-contests-tsx": () => import("./../../../src/pages/faqs/contests.tsx" /* webpackChunkName: "component---src-pages-faqs-contests-tsx" */),
  "component---src-pages-faqs-index-tsx": () => import("./../../../src/pages/faqs/index.tsx" /* webpackChunkName: "component---src-pages-faqs-index-tsx" */),
  "component---src-pages-faqs-payments-withdrawals-tsx": () => import("./../../../src/pages/faqs/payments-withdrawals.tsx" /* webpackChunkName: "component---src-pages-faqs-payments-withdrawals-tsx" */),
  "component---src-pages-faqs-trivia-tsx": () => import("./../../../src/pages/faqs/trivia.tsx" /* webpackChunkName: "component---src-pages-faqs-trivia-tsx" */),
  "component---src-pages-gambling-tsx": () => import("./../../../src/pages/gambling.tsx" /* webpackChunkName: "component---src-pages-gambling-tsx" */),
  "component---src-pages-games-tsx": () => import("./../../../src/pages/games.tsx" /* webpackChunkName: "component---src-pages-games-tsx" */),
  "component---src-pages-gangs-of-gamers-tsx": () => import("./../../../src/pages/gangs-of-gamers.tsx" /* webpackChunkName: "component---src-pages-gangs-of-gamers-tsx" */),
  "component---src-pages-getmega-carrom-party-tsx": () => import("./../../../src/pages/getmega-carrom-party.tsx" /* webpackChunkName: "component---src-pages-getmega-carrom-party-tsx" */),
  "component---src-pages-help-centre-contests-gameplays-tsx": () => import("./../../../src/pages/help-centre/contests-gameplays.tsx" /* webpackChunkName: "component---src-pages-help-centre-contests-gameplays-tsx" */),
  "component---src-pages-help-centre-game-rules-tsx": () => import("./../../../src/pages/help-centre/game-rules.tsx" /* webpackChunkName: "component---src-pages-help-centre-game-rules-tsx" */),
  "component---src-pages-help-centre-getting-started-tsx": () => import("./../../../src/pages/help-centre/getting-started.tsx" /* webpackChunkName: "component---src-pages-help-centre-getting-started-tsx" */),
  "component---src-pages-help-centre-index-tsx": () => import("./../../../src/pages/help-centre/index.tsx" /* webpackChunkName: "component---src-pages-help-centre-index-tsx" */),
  "component---src-pages-help-centre-kyc-tsx": () => import("./../../../src/pages/help-centre/kyc.tsx" /* webpackChunkName: "component---src-pages-help-centre-kyc-tsx" */),
  "component---src-pages-help-centre-payments-withdrawals-tsx": () => import("./../../../src/pages/help-centre/payments-withdrawals.tsx" /* webpackChunkName: "component---src-pages-help-centre-payments-withdrawals-tsx" */),
  "component---src-pages-help-centre-promotions-offers-tsx": () => import("./../../../src/pages/help-centre/promotions-offers.tsx" /* webpackChunkName: "component---src-pages-help-centre-promotions-offers-tsx" */),
  "component---src-pages-hi-index-tsx": () => import("./../../../src/pages/hi/index.tsx" /* webpackChunkName: "component---src-pages-hi-index-tsx" */),
  "component---src-pages-hi-poker-index-tsx": () => import("./../../../src/pages/hi/poker/index.tsx" /* webpackChunkName: "component---src-pages-hi-poker-index-tsx" */),
  "component---src-pages-hi-rummy-index-tsx": () => import("./../../../src/pages/hi/rummy/index.tsx" /* webpackChunkName: "component---src-pages-hi-rummy-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-install-real-money-gaming-app-tsx": () => import("./../../../src/pages/install-real-money-gaming-app.tsx" /* webpackChunkName: "component---src-pages-install-real-money-gaming-app-tsx" */),
  "component---src-pages-leaderboard-tsx": () => import("./../../../src/pages/leaderboard.tsx" /* webpackChunkName: "component---src-pages-leaderboard-tsx" */),
  "component---src-pages-listings-tsx": () => import("./../../../src/pages/listings.tsx" /* webpackChunkName: "component---src-pages-listings-tsx" */),
  "component---src-pages-new-gst-tsx": () => import("./../../../src/pages/new-gst.tsx" /* webpackChunkName: "component---src-pages-new-gst-tsx" */),
  "component---src-pages-news-tsx": () => import("./../../../src/pages/news.tsx" /* webpackChunkName: "component---src-pages-news-tsx" */),
  "component---src-pages-poker-books-tsx": () => import("./../../../src/pages/poker/books.tsx" /* webpackChunkName: "component---src-pages-poker-books-tsx" */),
  "component---src-pages-poker-free-practice-app-tsx": () => import("./../../../src/pages/poker-free-practice-app.tsx" /* webpackChunkName: "component---src-pages-poker-free-practice-app-tsx" */),
  "component---src-pages-poker-hands-tsx": () => import("./../../../src/pages/poker/hands.tsx" /* webpackChunkName: "component---src-pages-poker-hands-tsx" */),
  "component---src-pages-poker-index-tsx": () => import("./../../../src/pages/poker/index.tsx" /* webpackChunkName: "component---src-pages-poker-index-tsx" */),
  "component---src-pages-poker-learn-all-in-rules-in-poker-tsx": () => import("./../../../src/pages/poker/learn/all-in-rules-in-poker.tsx" /* webpackChunkName: "component---src-pages-poker-learn-all-in-rules-in-poker-tsx" */),
  "component---src-pages-poker-learn-basics-of-poker-tsx": () => import("./../../../src/pages/poker/learn/basics-of-poker.tsx" /* webpackChunkName: "component---src-pages-poker-learn-basics-of-poker-tsx" */),
  "component---src-pages-poker-learn-how-to-play-5-card-poker-tsx": () => import("./../../../src/pages/poker/learn/how-to-play-5-card-poker.tsx" /* webpackChunkName: "component---src-pages-poker-learn-how-to-play-5-card-poker-tsx" */),
  "component---src-pages-poker-learn-how-to-play-and-rules-of-badugi-tsx": () => import("./../../../src/pages/poker/learn/how-to-play-and-rules-of-badugi.tsx" /* webpackChunkName: "component---src-pages-poker-learn-how-to-play-and-rules-of-badugi-tsx" */),
  "component---src-pages-poker-learn-how-to-play-chinese-poker-tsx": () => import("./../../../src/pages/poker/learn/how-to-play-chinese-poker.tsx" /* webpackChunkName: "component---src-pages-poker-learn-how-to-play-chinese-poker-tsx" */),
  "component---src-pages-poker-learn-how-to-play-hi-lo-omaha-poker-tsx": () => import("./../../../src/pages/poker/learn/how-to-play-hi-lo-omaha-poker.tsx" /* webpackChunkName: "component---src-pages-poker-learn-how-to-play-hi-lo-omaha-poker-tsx" */),
  "component---src-pages-poker-learn-how-to-play-horse-poker-tsx": () => import("./../../../src/pages/poker/learn/how-to-play-horse-poker.tsx" /* webpackChunkName: "component---src-pages-poker-learn-how-to-play-horse-poker-tsx" */),
  "component---src-pages-poker-learn-how-to-play-pineapple-poker-tsx": () => import("./../../../src/pages/poker/learn/how-to-play-pineapple-poker.tsx" /* webpackChunkName: "component---src-pages-poker-learn-how-to-play-pineapple-poker-tsx" */),
  "component---src-pages-poker-learn-how-to-play-poker-like-a-pro-tsx": () => import("./../../../src/pages/poker/learn/how-to-play-poker-like-a-pro.tsx" /* webpackChunkName: "component---src-pages-poker-learn-how-to-play-poker-like-a-pro-tsx" */),
  "component---src-pages-poker-learn-how-to-play-rules-of-super-texas-holdem-tsx": () => import("./../../../src/pages/poker/learn/how-to-play-rules-of-super-texas-holdem.tsx" /* webpackChunkName: "component---src-pages-poker-learn-how-to-play-rules-of-super-texas-holdem-tsx" */),
  "component---src-pages-poker-learn-how-to-play-short-deck-poker-tsx": () => import("./../../../src/pages/poker/learn/how-to-play-short-deck-poker.tsx" /* webpackChunkName: "component---src-pages-poker-learn-how-to-play-short-deck-poker-tsx" */),
  "component---src-pages-poker-learn-learn-how-to-play-texas-holdem-tsx": () => import("./../../../src/pages/poker/learn/learn-how-to-play-texas-holdem.tsx" /* webpackChunkName: "component---src-pages-poker-learn-learn-how-to-play-texas-holdem-tsx" */),
  "component---src-pages-poker-learn-learn-omaha-poker-tsx": () => import("./../../../src/pages/poker/learn/learn-omaha-poker.tsx" /* webpackChunkName: "component---src-pages-poker-learn-learn-omaha-poker-tsx" */),
  "component---src-pages-poker-learn-open-face-chinese-poker-tsx": () => import("./../../../src/pages/poker/learn/open-face-chinese-poker.tsx" /* webpackChunkName: "component---src-pages-poker-learn-open-face-chinese-poker-tsx" */),
  "component---src-pages-poker-learn-poker-chips-and-their-values-tsx": () => import("./../../../src/pages/poker/learn/poker-chips-and-their-values.tsx" /* webpackChunkName: "component---src-pages-poker-learn-poker-chips-and-their-values-tsx" */),
  "component---src-pages-poker-learn-razz-omaha-tsx": () => import("./../../../src/pages/poker/learn/razz-omaha.tsx" /* webpackChunkName: "component---src-pages-poker-learn-razz-omaha-tsx" */),
  "component---src-pages-poker-learn-rules-of-2-7-triple-draw-tsx": () => import("./../../../src/pages/poker/learn/rules-of-2-7-triple-draw.tsx" /* webpackChunkName: "component---src-pages-poker-learn-rules-of-2-7-triple-draw-tsx" */),
  "component---src-pages-poker-learn-rules-of-7-card-stud-poker-tsx": () => import("./../../../src/pages/poker/learn/rules-of-7-card-stud-poker.tsx" /* webpackChunkName: "component---src-pages-poker-learn-rules-of-7-card-stud-poker-tsx" */),
  "component---src-pages-poker-learn-tsx": () => import("./../../../src/pages/poker/learn.tsx" /* webpackChunkName: "component---src-pages-poker-learn-tsx" */),
  "component---src-pages-poker-learn-types-of-poker-games-tsx": () => import("./../../../src/pages/poker/learn/types-of-poker-games.tsx" /* webpackChunkName: "component---src-pages-poker-learn-types-of-poker-games-tsx" */),
  "component---src-pages-poker-poker-app-download-tsx": () => import("./../../../src/pages/poker/poker-app-download.tsx" /* webpackChunkName: "component---src-pages-poker-poker-app-download-tsx" */),
  "component---src-pages-poker-poker-cards-tsx": () => import("./../../../src/pages/poker/poker-cards.tsx" /* webpackChunkName: "component---src-pages-poker-poker-cards-tsx" */),
  "component---src-pages-poker-poker-hierarchy-tsx": () => import("./../../../src/pages/poker/poker-hierarchy.tsx" /* webpackChunkName: "component---src-pages-poker-poker-hierarchy-tsx" */),
  "component---src-pages-poker-poker-odds-calculator-tsx": () => import("./../../../src/pages/poker/poker-odds-calculator.tsx" /* webpackChunkName: "component---src-pages-poker-poker-odds-calculator-tsx" */),
  "component---src-pages-poker-query-ts": () => import("./../../../src/pages/poker/query.ts" /* webpackChunkName: "component---src-pages-poker-query-ts" */),
  "component---src-pages-poker-rmg-tsx": () => import("./../../../src/pages/poker/rmg.tsx" /* webpackChunkName: "component---src-pages-poker-rmg-tsx" */),
  "component---src-pages-poker-strategy-tsx": () => import("./../../../src/pages/poker/strategy.tsx" /* webpackChunkName: "component---src-pages-poker-strategy-tsx" */),
  "component---src-pages-poker-tds-policy-tsx": () => import("./../../../src/pages/poker-tds-policy.tsx" /* webpackChunkName: "component---src-pages-poker-tds-policy-tsx" */),
  "component---src-pages-poker-terms-data-ts": () => import("./../../../src/pages/poker/termsData.ts" /* webpackChunkName: "component---src-pages-poker-terms-data-ts" */),
  "component---src-pages-poker-terms-tsx": () => import("./../../../src/pages/poker/terms.tsx" /* webpackChunkName: "component---src-pages-poker-terms-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-psa-certifications-tsx": () => import("./../../../src/pages/psa/certifications.tsx" /* webpackChunkName: "component---src-pages-psa-certifications-tsx" */),
  "component---src-pages-psa-responsible-gaming-tsx": () => import("./../../../src/pages/psa/responsible-gaming.tsx" /* webpackChunkName: "component---src-pages-psa-responsible-gaming-tsx" */),
  "component---src-pages-psa-wifi-tsx": () => import("./../../../src/pages/psa/wifi.tsx" /* webpackChunkName: "component---src-pages-psa-wifi-tsx" */),
  "component---src-pages-real-money-gaming-apps-tsx": () => import("./../../../src/pages/real-money-gaming-apps.tsx" /* webpackChunkName: "component---src-pages-real-money-gaming-apps-tsx" */),
  "component---src-pages-refer-and-earn-tsx": () => import("./../../../src/pages/refer-and-earn.tsx" /* webpackChunkName: "component---src-pages-refer-and-earn-tsx" */),
  "component---src-pages-responsible-gaming-tsx": () => import("./../../../src/pages/responsible-gaming.tsx" /* webpackChunkName: "component---src-pages-responsible-gaming-tsx" */),
  "component---src-pages-rummy-free-practice-app-tsx": () => import("./../../../src/pages/rummy-free-practice-app.tsx" /* webpackChunkName: "component---src-pages-rummy-free-practice-app-tsx" */),
  "component---src-pages-rummy-index-tsx": () => import("./../../../src/pages/rummy/index.tsx" /* webpackChunkName: "component---src-pages-rummy-index-tsx" */),
  "component---src-pages-rummy-learn-tsx": () => import("./../../../src/pages/rummy/learn.tsx" /* webpackChunkName: "component---src-pages-rummy-learn-tsx" */),
  "component---src-pages-rummy-privacy-policy-tsx": () => import("./../../../src/pages/rummy-privacy-policy.tsx" /* webpackChunkName: "component---src-pages-rummy-privacy-policy-tsx" */),
  "component---src-pages-rummy-rmg-tsx": () => import("./../../../src/pages/rummy/rmg.tsx" /* webpackChunkName: "component---src-pages-rummy-rmg-tsx" */),
  "component---src-pages-rummy-tds-policy-tsx": () => import("./../../../src/pages/rummy-tds-policy.tsx" /* webpackChunkName: "component---src-pages-rummy-tds-policy-tsx" */),
  "component---src-pages-send-sms-tsx": () => import("./../../../src/pages/send-sms.tsx" /* webpackChunkName: "component---src-pages-send-sms-tsx" */),
  "component---src-pages-sitemap-tsx": () => import("./../../../src/pages/sitemap.tsx" /* webpackChunkName: "component---src-pages-sitemap-tsx" */),
  "component---src-pages-tds-policy-tsx": () => import("./../../../src/pages/tds-policy.tsx" /* webpackChunkName: "component---src-pages-tds-policy-tsx" */),
  "component---src-pages-terms-of-use-tsx": () => import("./../../../src/pages/terms-of-use.tsx" /* webpackChunkName: "component---src-pages-terms-of-use-tsx" */),
  "component---src-pages-testimonials-tsx": () => import("./../../../src/pages/testimonials.tsx" /* webpackChunkName: "component---src-pages-testimonials-tsx" */),
  "component---src-pages-tournament-tsx": () => import("./../../../src/pages/tournament.tsx" /* webpackChunkName: "component---src-pages-tournament-tsx" */),
  "component---src-pages-trivia-123-maths-quiz-tsx": () => import("./../../../src/pages/trivia/123-maths-quiz.tsx" /* webpackChunkName: "component---src-pages-trivia-123-maths-quiz-tsx" */),
  "component---src-pages-trivia-gk-tsx": () => import("./../../../src/pages/trivia/gk.tsx" /* webpackChunkName: "component---src-pages-trivia-gk-tsx" */),
  "component---src-pages-trivia-index-tsx": () => import("./../../../src/pages/trivia/index.tsx" /* webpackChunkName: "component---src-pages-trivia-index-tsx" */),
  "component---src-pages-trivia-pic-me-tsx": () => import("./../../../src/pages/trivia/pic-me.tsx" /* webpackChunkName: "component---src-pages-trivia-pic-me-tsx" */),
  "component---src-pages-web-stories-index-tsx": () => import("./../../../src/pages/web-stories/index.tsx" /* webpackChunkName: "component---src-pages-web-stories-index-tsx" */),
  "component---src-pages-web-stories-poker-tsx": () => import("./../../../src/pages/web-stories/poker.tsx" /* webpackChunkName: "component---src-pages-web-stories-poker-tsx" */),
  "component---src-pages-web-stories-rummy-tsx": () => import("./../../../src/pages/web-stories/rummy.tsx" /* webpackChunkName: "component---src-pages-web-stories-rummy-tsx" */),
  "component---src-pages-winners-tsx": () => import("./../../../src/pages/winners.tsx" /* webpackChunkName: "component---src-pages-winners-tsx" */),
  "component---src-pages-you-got-referred-tsx": () => import("./../../../src/pages/you-got-referred.tsx" /* webpackChunkName: "component---src-pages-you-got-referred-tsx" */),
  "component---src-templates-authors-template-tsx": () => import("./../../../src/templates/authors/Template.tsx" /* webpackChunkName: "component---src-templates-authors-template-tsx" */),
  "component---src-templates-blog-template-tsx": () => import("./../../../src/templates/BlogTemplate.tsx" /* webpackChunkName: "component---src-templates-blog-template-tsx" */),
  "component---src-templates-coupons-template-tsx": () => import("./../../../src/templates/coupons/Template.tsx" /* webpackChunkName: "component---src-templates-coupons-template-tsx" */),
  "component---src-templates-news-template-tsx": () => import("./../../../src/templates/NewsTemplate.tsx" /* webpackChunkName: "component---src-templates-news-template-tsx" */),
  "component---src-templates-poker-player-index-tsx": () => import("./../../../src/templates/poker-player/index.tsx" /* webpackChunkName: "component---src-templates-poker-player-index-tsx" */),
  "component---src-templates-real-money-games-template-tsx": () => import("./../../../src/templates/real-money-games/Template.tsx" /* webpackChunkName: "component---src-templates-real-money-games-template-tsx" */)
}

